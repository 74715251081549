<template lang="pug">
.TakeAnalysis
  .TakeAnalysis-Header
    //- TODO icon system
    svg.TakeAnalysis-Logo(width="38" height="38" viewbox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg")
      rect(width="38" height="38" rx="12" fill="#FD7844")
      path(d="M26.3663 21.4532L25.2376 21.9548C25.0119 22.032 24.899 22.3021 25.0119 22.5336C25.2752 23.2667 25.2752 23.8455 25.2 24.2699C25.1624 24.3857 25.0495 24.4628 24.9366 24.4243C23.6574 23.7683 22.6792 22.6108 22.2277 21.2217C23.9584 20.7587 25.3881 19.9484 26.5168 18.7909C29 16.2829 29 13.119 29 13.0032V12.463C29 12.1929 28.8119 12 28.5485 12H27.3446C23.2812 12 19.9327 15.3955 20.0079 19.6012C19.6693 19.6398 19.105 19.6012 19.0297 19.6012C19.0297 19.5626 19.0297 19.5626 19.0297 19.524C19.0297 15.3569 15.7188 12 11.6931 12H10.4515C10.1881 12 10 12.1929 10 12.463V13.0032C10 13.119 10 16.3215 12.4832 18.7909C13.6119 19.9484 15.0416 20.7587 16.7723 21.2217C16.3208 22.6108 15.3426 23.7683 14.0634 24.4243C13.9505 24.5014 13.8 24.4243 13.8 24.2699C13.7248 23.8455 13.7624 23.2667 13.9881 22.5336C14.0634 22.3021 13.9505 22.0706 13.7624 21.9548L12.6337 21.4532C12.4832 21.3761 12.2574 21.4532 12.2198 21.6462C11.1663 24.5014 12.4079 26.1992 12.8594 26.7393C12.9723 26.8551 13.1228 26.8937 13.2733 26.8551C14.4772 26.585 17.6 25.5046 18.7287 21.6848C19.2178 21.7619 20.2713 21.7233 20.3842 21.6848C21.5129 25.5432 24.6356 26.585 25.8396 26.8551C25.9901 26.8937 26.1406 26.8551 26.2535 26.7393C26.7426 26.1992 27.9465 24.5014 26.8931 21.6462C26.7426 21.4532 26.5545 21.3761 26.3663 21.4532ZM13.8752 17.3247C12.8594 16.3215 12.4079 15.1639 12.1822 14.2765C12.1446 14.1607 12.2574 14.0064 12.4079 14.045C14.9287 14.3922 16.8851 16.5144 17.0356 19.1382C15.7188 18.7523 14.703 18.135 13.8752 17.3247ZM21.9644 19.1382C22.1148 16.5144 24.0713 14.3922 26.5921 14.045C26.7426 14.045 26.8554 14.1607 26.8178 14.2765C26.5921 15.1639 26.103 16.3215 25.1248 17.3247C24.297 18.135 23.2436 18.7523 21.9644 19.1382Z" fill="#FFF9F8")
    .TakeAnalysis-Title Сдать анализ
    .TakeAnalysis-Subtitle(v-if="servicesLength") {{servicesLength}} услуги
  .TakeAnalysis-Text Это исследование входит в несколько услуг и чек-апов ЛабСтори.
  BaseBtn.TakeAnalysis-Button(type="outline" size="sm" @click="isModalOpen = true") Посмотреть список услуг
  TakeAnalysisModal(v-model="isModalOpen" :services="services")
</template>

<script>
import TakeAnalysisModal from './TakeAnalysisModal.vue';

export default {
  name: 'TakeAnalysis',
  components: {
    TakeAnalysisModal
  },
  data(){
    return {
      isModalOpen: false,
      services: []
    }
  },
  async mounted() {
    this.services = await this.$api.analyzes.getAnalyzeServiceCatalog({id: this.$route.params.id});
  },
  computed: {
    servicesLength(){
      return this.services?.length
    }
  }
}
</script>

<style lang="scss">
.TakeAnalysis {
  padding: 18px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 18px;
  background: white;
  border-radius: 20px;
}

.TakeAnalysis-Header {
  display: grid;
  grid-template-columns: auto auto;
  gap: 2px 12px;
  align-items: center;
  justify-content: flex-start;
}

.TakeAnalysis-Logo {
  grid-row: span 2;
}

.TakeAnalysis-Title {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: $grey-2;
}

.TakeAnalysis-Subtitle {
  font-size: 12px;
  line-height: 15px;
  color: $grey-4;
}

.TakeAnalysis-Text {
  font-size: 14px;
  line-height: 20px;
  color: $grey-2;
}
</style>