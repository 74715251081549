<template lang="pug">
MessageModal(
  :value="value"
  @input="$emit('input', false)"
  title="Мы перевели результаты и референсные зоны в нашу единицу измерения"
  rowButtons
  size="lg")
  template(#subtitle)
    .flex.items-center.q-gutter-sm
      .convert-modal__value-group 
        .text-grey-1.weight-medium {{`${rounding(prevValue)} ${prevUnit}` || 'nodata'}}
        .text-grey-4 {{prevRange}}
        .convert-modal__value-status(:class="classObject")
      icon(name="next-icon3" color="#7C74E9" width="24px" height="24px")
      .convert-modal__value-group 
        .text-grey-1.weight-medium {{`${rounding(newValue)} ${newUnit}` || 'nodata'}}
        .text-grey-4 {{newRange}}
        .convert-modal__value-status(:class="classObject")
    .text-grey-4 Интерпретация результата при этом не изменилась. 
  template(#body): div.
    Вы загрузили биомаркер в <span class="text-primary">{{prevUnit}}</span>, а мы его перевели в <span class="text-primary">{{newUnit}}</span> . Также мы конвертировали и референсные значения — это надо для того, чтобы дать вам возможность сравнивать результаты между собой в динамике.<br>
    Вы в любой момент можете переключить единицу измерения этого биомаркера, чтобы отображать результаты в удобном вам виде.
  template(#buttons)
    BaseBtn(size="sm" @click="$emit('input', false)").convert-modal__ok-button Все понятно
    BaseBtn(size="sm" type="outline" href="https://labstory.notion.site/Copy-of-2cdcd58bcfb444ef858603d39482f428#6ad40109b9044b07813d93721558f17e" ) Подробнее про перевод
</template>
<script>
import MessageModal from '@/components/modals_new/templates/MessageModal.vue';
import {rounding} from '@/utils/count-helpers.js';

export default {
  components: {MessageModal},
  props:['value', 'prevUnit', 'newUnit', 'range_zone', 'newRange', 'prevRange', 'prevValue', 'newValue'],
  computed: {
    classObject() {
      const {range_zone} = this;
      return {
        ['bg-green']: range_zone === 'in_norm',
        ['bg-yellow']: range_zone === 'border_zone',
        ['bg-red']: range_zone === 'out_of_norm'
      }
    } 
  },
  methods: {rounding}
}
</script>

<style lang="scss">
  .convert-modal__ok-button {
    width: 275px;
  }
  .convert-modal__value-group {
    width: 200px;
    border-radius: 18px;
    padding: 8px 14px;
    border: 1px solid $grey-5;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .convert-modal__value-status {
    height: 4px;
    background: grey;
    width: 100%;
    border-radius: 100px;
    margin-top: 10px;
  }
</style>
