// TODO refact
<template lang="pug">
  .comment-expansion
    q-expansion-item.comment-expansion__item(
      :value="value" 
      @input="$emit('input'), !value")
      template(#header)
        icon(name="comment-icon" height="20")
        .comment-expansion__title {{title}}
      .comment-expansion__body {{ comment }}
        BaseBtn(
          v-if="editable"
          class="q-mt-md"
          type="outline"
          icon="edit-icon"
          :iconSize="10"
          wide
          @click="$emit('edit')"
        ) Редактировать
</template>

<script>
export default {
  props: ['value', 'comment', 'editable', 'title']
}
</script>

<style lang="scss">
.comment-expansion {
  background: white;
  border-radius: 15px;
  padding: 15px;
  align-self: flex-start;
  @include media-down($breakpoint-md) {
    width: 100%;
  }
  .q-item {
    display: flex !important;
    align-items: center !important;
    min-height: initial !important;
    padding: 0 !important;
  }

  .q-item__section {
    padding: 0 !important;
    margin-left: auto !important;
    width: 24px !important;
    height: 24px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border: 1px solid $light-stroke !important;
    border-radius: 10px !important;
  }

  i {
    font-size: 12px !important;
    color: $accent-color !important;
  }
}

.comment-expansion__item {
  margin-left: -18px;
  margin-right: -18px;
  // margin-bottom: -18px;
  padding: 0 18px !important;
}

.comment-expansion__title {
  user-select:none;
  font-weight: 400;
  font-size: 14px;
  // line-height: 57px;
  color: $black-01;
  margin: 0 12px;
  // height: 57px;
}

.comment-expansion__body {
  margin-top: 1em;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: $black-03;
}


</style>

