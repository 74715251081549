export const style = {
  label: false,
  borderColor: '#7a72e9',
  borderWidth: 2,
  backgroundColor: null,

  pointBorderColor: 'white',
  pointBorderWidth: 2,
  pointHitRadius: 10,
  pointRadius: 4,

  pointHoverBorderColor: 'white',
  pointHoverBorderWidth: 4,
  pointHoverRadius: 5,
  spanGaps: true,
};

export const config = {};

export const options = {
  legend: {
    display: false,
  },
  tooltips: {
    displayColors: false,
    bodyFontFamily: 'Euclid Circular A',
    titleFontFamily: 'Euclid Circular A',
    backgroundColor: '#7C74E9',
    fontColor: '#FFFFFF',
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    xAxes: [
      {
        ticks: {
          padding: 11,
          fontFamily: 'Euclid Circular A',
          fontColor: '#A0A0B8',
          padding: 7,
        },
        labelString: 'Дата',
        gridLines: {
          tickMarkLength: 0,
          drawOnChartArea: false,
        },
        offset: false,
      },
    ],
    yAxes: [
      {
        gridLines: {
          borderDashOffset: 111,
          tickMarkLength: 0,
          drawOnChartArea: false,
        },
        ticks: {
          padding: 12,
          fontFamily: 'Euclid Circular A',
          fontColor: '#A0A0B8',
        },
      },
    ],
  },
};

const negativePointStar = new Image(15, 15);
const positivePointStar = new Image(15, 15);
const borderPointStar = new Image(15, 15);

negativePointStar.src = `${require('@/assets/chart/star-negative.svg')}`;
positivePointStar.src = `${require('@/assets/chart/star-positive.svg')}`;
borderPointStar.src = `${require('@/assets/chart/star-border.svg')}`;

export const svgs = {
  negativePointStar,
  positivePointStar,
  borderPointStar,
};
