import { __decorate } from "tslib";
import { Component, Emit, Vue, Watch } from 'vue-property-decorator';
import BackBtn from '@/components/UI/buttons/BackBtn.vue';
import MainBtn from '@/components/UI/buttons/MainBtn.vue';
import ThirdType from '@/components/modals/ChartMobileFilter/ThirdType.vue';
import { mobilePeriodList } from '@/utils/utils';
let SecondType = class SecondType extends Vue {
    constructor() {
        super(...arguments);
        this.localType = 1;
        this.active = 2;
        this.periodValue = {
            from: '',
            to: '',
        };
    }
    mounted() {
        this.active = this.selectedPeriod;
    }
    setActive(val) {
        this.active = val;
    }
    get periodList() {
        return mobilePeriodList;
    }
    get selectedPeriod() {
        return this.$store.state.filter.selectedPeriod;
    }
    get selectedPeriodDateRange() {
        return this.$store.state.filter.selectedPeriodDateRange;
    }
    get selectDateRange() {
        return this.periodList.find((el) => el.id === this.active);
    }
    changeType() {
        if (this.localType === 1) {
            this.changeMainType();
        }
        this.localType = 1;
    }
    setPeriod() {
        if (this.selectedPeriod === 4)
            this.changeMainType();
        if (this.active === 4)
            return;
        this.$store.commit('filter/setPropertyInStore', { name: 'selectedPeriod', value: this.active });
        this.$store.commit('filter/setPropertyInStore', {
            name: 'selectedPeriodDateRange',
            value: this.selectDateRange?.dateRange,
        });
        this.changeMainType();
    }
    changeMainType() {
        return 0;
    }
};
__decorate([
    Watch('selectedPeriod')
], SecondType.prototype, "setActive", null);
__decorate([
    Emit('change-type')
], SecondType.prototype, "changeMainType", null);
SecondType = __decorate([
    Component({
        components: { ThirdType, MainBtn, BackBtn },
    })
], SecondType);
export default SecondType;
