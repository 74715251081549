<script>
import { Line } from "vue-chartjs";
import { style, config, options, svgs } from "./chartConfig.js";
import {rounding, getRangeText} from '@/utils/count-helpers.js';


export default {
  extends: Line,
  props:['results'],
  mounted() {
    this.addHorizontalLinePlugin()
    this.generateGradient()
    this.setDataChart()
    // this.addOffsetPlugin()
  },
  watch: {
    results(){
      this.setDataChart()
    },
  },
  methods: {
    setDataChart(){
      this.generateOptions()
      this.renderChart(config,options);
    },

    generateOptions() {
      config.datasets = [
        {
          data: this.chartData,
          ...style,
          ...this.pointStyles,
        }
      ],
      config.labels = this.xAxisLabels
      // options.scales.xAxes[0].ticks = {
      //   min: this.$date(new Date(this.dateRange[0]), 'yyyy-MM-dd'),
      //   max: this.$date(new Date(this.dateRange[1]), 'yyyy-MM-dd')
      // }
      options.scales.yAxes[0].ticks.min = rounding(this.minmaxYTicks.min)
      options.scales.yAxes[0].ticks.max = rounding(this.minmaxYTicks.max)
      options.scales.yAxes[0].ticks.callback = (value, index, values) => {
        // console.log(value, index, values)
        const middleIndex = Math.round((values.length - 1) / 2);
        if (index === values.length - 1) return rounding(this.minmaxYTicks.min);
        else if (index === 0) return rounding(this.minmaxYTicks.max);
        else if (index == middleIndex) return rounding(values[middleIndex])
        else return '';
      }
      const currentResult = (tooltipItem) => {
        const indexOfResult = Array.isArray(tooltipItem) ? tooltipItem[0].index : tooltipItem.index
        return this.resultsByTimeOrder[indexOfResult];
      };

      options.tooltips.callbacks = {
        title: (item) => `${rounding(currentResult(item).value)} ${currentResult(item).unit}`,
        beforeLabel: (item) => this.$date(new Date(currentResult(item).date), 'd MMMM yyyy'),
        label: (item) =>  currentResult(item).laboratory,
        afterLabel: (item) => `${getRangeText(currentResult(item).ranges, 1, 2, 'word')} ${currentResult(item).unit}`,
      };

      if (this.everyResultRangeAreEqual && this.resultsByTimeOrder.length !== 0) {
        options.ranges = this.resultsByTimeOrder[0].ranges;
      }
    },
    

    generateGradient(){
      const gradient = this.$refs.canvas.getContext("2d").createLinearGradient(0, 0, 0, 300);
      const rgbColor = '122, 114, 233'
      const white = '0, 0, 0'

      gradient.addColorStop(0, `rgba(${rgbColor}, 0.5)`);
      gradient.addColorStop(1, `rgba(${rgbColor}, 0)`);
      style.backgroundColor = gradient
    },

    addHorizontalLinePlugin(){
      this.addPlugin({
        id: 'horizontalLine',
        afterDatasetDraw: function(chart) {
          if (typeof chart.config.options.ranges != 'undefined') {
            const ranges = chart.config.options.ranges
            const rangesArr = [ranges.min, ranges.max];
            const ctxPlugin = chart.chart.ctx;
            const xAxe = chart.scales[chart.config.options.scales.xAxes[0].id];
            const yAxe = chart.scales[chart.config.options.scales.yAxes[0].id];
            const {min, max, bottom, top,} = yAxe

            const drawLine = (y, range, percent) => {
              ctxPlugin.strokeStyle = "#63C58A";
              ctxPlugin.lineWidth = 1;
              ctxPlugin.setLineDash([3, 3]);
              
              ctxPlugin.beginPath();
              ctxPlugin.moveTo(xAxe.left, y);
              ctxPlugin.lineTo(xAxe.right, y);
              ctxPlugin.stroke();

              ctxPlugin.fillStyle = "#63C58A";
              ctxPlugin.textAlign = "right";
              ctxPlugin.font = "normal 12px Euclid Circular A";

              const textYPosititon = percent < 2 ? y + 15 : y - 5
              ctxPlugin.fillText(range, xAxe.right - 5, textYPosititon, 50);
              ctxPlugin.setLineDash([]);
            }
            // console.log(ctxPlugin)

            rangesArr.forEach(range => {
              if (range > max || range < min) return
              const percent = (max - range) * 100 / (max - min)
              const yPosition = ((bottom - top) / 100) * percent + top
              drawLine(yPosition, range, percent)
            })
          }
        }
      });
    },

    addOffsetPlugin(){
        this.addPlugin({
        id: 'drawOffset',
        afterDatasetDraw: function(chart) {
          // console.log(chart)
            const dataset = chart.config.data.datasets[0];
            const offset = 26;
            const metaFirst = dataset._meta[Object.keys(dataset._meta)[0]]
            // console.log(metaFirst)

            // metaFirst.data.forEach(element => {
            //   const model = element._model;
            //   model.x += offset;
            //   model.controlPointNextX += offset;
            //   model.controlPointPreviousX += offset;
            // })
            }


        });
    },
  },
  computed: {
    pointStyles(){
      const {resultsByTimeOrder} = this 

      const colorsObj = {in_norm: '#63C58A', out_of_norm: '#FF7C7C', border_zone: 'yellow'}
      const starsObj = {in_norm: svgs.positivePointStar, out_of_norm: svgs.negativePointStar, border_zone: svgs.borderPointStar}

      const condsObj = {
        pointStyle: result => result.laboratory_id === 0 ? 'circle' : starsObj[result.range_zone],
        pointBorderColor: result => result.laboratory_id === 0 ? 'white' : colorsObj[result.range_zone],
        pointBackgroundColor: result => colorsObj[result.range_zone],
      }

      return {
        pointStyle: resultsByTimeOrder.map(condsObj.pointStyle),
        pointBackgroundColor: resultsByTimeOrder.map(condsObj.pointBackgroundColor),
        pointBorderColor: resultsByTimeOrder.map(condsObj.pointBorderColor)
      }
    },
    resultsByTimeOrder(){
      return [...this.results].reverse()
    },
    xAxisLabels(){
      let lastYear = 1;
      return this.resultsByTimeOrder.map(result => {
        let formatData = 'dd MMM'
        const date = new Date(result.date)
        const displayYear = date.getFullYear() > lastYear

        if (displayYear) {
          formatData = 'dd MMM yy'
          lastYear = date.getFullYear()
        } 

        return this.$date(date, formatData)
      })
    },
    chartData(){
      // const restructureResults = result => ({x: result.date, y: result.value, data: result})
      const restructureResults = result => (result.value)
      
      // const sortResults = (a, b) => Date.parse(a.x) - Date.parse(b.x)
      // const filterByRangeResults = result => {
      //   const endDate = Date.parse(this.dateRange[1])
      //   const targetDate = Date.parse(result.date)
      //   console.log(targetDate, this.startDate,endDate, (targetDate > this.startDate) && (targetDate < endDate))
      //   return (targetDate > this.startDate) && (targetDate < endDate)
      // }
      return this.resultsByTimeOrder.map(restructureResults)
        // .sort(sortResults)
        // .filter(filterByRangeResults)
    },
    minmaxYTicks(){
      const percentOffsetY = 10
      const {everyResultRangeAreEqual} = this
      const firstResult = this.resultsByTimeOrder[0]
      if (!firstResult){
        return {
          min: 0, max: 1
        }
      }
      const {min: minRange, max: maxRange} = firstResult.ranges
      const minValue = this.resultsByTimeOrder.reduce((acc, curr) => acc < curr.value ? acc : curr.value, this.resultsByTimeOrder[0].value)
      const maxValue = this.resultsByTimeOrder.reduce((acc, curr) => acc > curr.value ? acc : curr.value, this.resultsByTimeOrder[0].value)

      const minPoint = everyResultRangeAreEqual ? Math.min(minValue, minRange) : minValue
      const maxPoint = everyResultRangeAreEqual ? Math.max(maxValue, maxRange) : maxValue

      
      const offsetY = (maxPoint - minPoint) / 100 * percentOffsetY
      // console.log(minPoint)
      // const min = minPoint <= 0 ? 0 : minPoint - offsetY 
      const min = minPoint - offsetY <= 0 ? 0 : minPoint - offsetY 
      const max = maxPoint + offsetY
      return {min, max}

    },
    everyResultRangeAreEqual(){
      return this.resultsByTimeOrder?.every(result => {
        if (this.resultsByTimeOrder.length === 0) return false
        const nextResultRanges = result.ranges
        // if (nextResultRanges === null) return false
        const {min: nMin, max: nMax} = nextResultRanges
        const {min: fMin, max: fMax} = this.resultsByTimeOrder?.[0]?.ranges
        return nMin === fMin && nMax === fMax
      })
    }
  }
};
</script>