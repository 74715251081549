import { __decorate } from "tslib";
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import MainBtn from '@/components/UI/buttons/MainBtn.vue';
import CloseBtn from '@/components/UI/buttons/CloseBtn.vue';
import MainType from '@/components/modals/ChartMobileFilter/MainType.vue';
import FirstType from '@/components/modals/ChartMobileFilter/FirstType.vue';
import SecondType from '@/components/modals/ChartMobileFilter/SecondType.vue';
import ThirdType from '@/components/modals/ChartMobileFilter/ThirdType.vue';
import FourthType from '@/components/modals/ChartMobileFilter/FourthType.vue';
import { subDays } from 'date-fns';
let ChartMobileFilter = class ChartMobileFilter extends Vue {
    constructor() {
        super(...arguments);
        this.type = 0;
    }
    mounted() { }
    get selectedLaboratories() {
        return this.$store.state.filter.selectedLaboratories;
    }
    get selectedPeriod() {
        return this.$store.state.filter.selectedPeriod;
    }
    get selectedPeriodDateRange() {
        return this.$store.state.filter.selectedPeriodDateRange;
    }
    closeModal() {
        return false;
    }
    applyChanges() {
        const selected = { ...this.selectedLaboratories };
        this.closeModal();
        return selected;
    }
    changeVisible(arr) {
        return arr;
    }
    changeType(val) {
        this.type = val;
    }
    resetResults() {
        this.$store.commit('filter/setPropertyInStore', { name: 'selectedPeriod', value: 2 });
        this.$store.commit('filter/setPropertyInStore', {
            name: 'selectedPeriodDateRange',
            value: [subDays(new Date(), 90), new Date()],
        });
    }
};
__decorate([
    Prop({ required: true })
], ChartMobileFilter.prototype, "isFilterOpen", void 0);
__decorate([
    Prop()
], ChartMobileFilter.prototype, "results", void 0);
__decorate([
    Prop()
], ChartMobileFilter.prototype, "laboratoryList", void 0);
__decorate([
    Emit('close-modal')
], ChartMobileFilter.prototype, "closeModal", null);
__decorate([
    Emit('apply-changes')
], ChartMobileFilter.prototype, "applyChanges", null);
__decorate([
    Emit('change-visible')
], ChartMobileFilter.prototype, "changeVisible", null);
ChartMobileFilter = __decorate([
    Component({
        components: { FourthType, ThirdType, SecondType, FirstType, MainType, MainBtn, CloseBtn },
    })
], ChartMobileFilter);
export default ChartMobileFilter;
