import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IAnalyzes } from '@/interfaces/analyzes.interface';
import MainBtn from '@/components/UI/buttons/MainBtn.vue';
import AnalyzeItem from '@/components/items/AnalyzeItem.vue';
import EditAnalyzes from '@/components/modals/EditAnalyzes.vue';
import MessageModal from '@/components/modals_new/templates/MessageModal.vue';
import AddComment from '@/components/modals/AddComment.vue';
import { bus } from '@/plugins/bus';
import ConfirmationModal from '@/components/modals_new/templates/ConfirmationModal.vue';
import AnalyzeForm from '@/components/modals_new/complex/AnalyzeForm.vue';
import { deleteResult } from '@/api/analyzes.js';
import { cloneDeep } from 'lodash';
let HistoryAnalyzes = class HistoryAnalyzes extends Vue {
    constructor() {
        super(...arguments);
        this.showMobileActionsMenu = null;
        this.editAnalyzesModalState = false;
        this.addCommentModalState = false;
        this.editData = {};
        this.isShowComment = false;
        this.comment = '';
        // TODO DO DRY (3 components)
        // NEW EDIT 
        this.formProp = null;
        this.isOpenAnalyzeForm = false;
        this.withFocusOnComment = null;
        // NEW REMOVE
        this.isShowConfirmationModal = false;
    }
    showComment($event) {
        this.isShowComment = true;
        this.comment = $event;
    }
    mounted() {
        bus.$on(IAnalyzes.BusEvents.OPEN_ADD_ANALYZES_COMMENT, (id) => {
            this.addCommentModalState = !this.addCommentModalState;
            this.$store.commit('analyzes/setPropertyInStore', { name: 'commentAnalyzesId', value: id });
        });
    }
    get resultList() {
        const results = this.results && this.results.results.length ? this.results.results : [];
        if (this.results.type === 'enum') {
            results.forEach((item) => {
                item.biomarker_enum_values = this.results.enum_values;
            });
        }
        const coeff = this.selectedUnit?.coeff;
        if (this.results.type !== 'enum' && coeff !== null) {
            const convertedReslults = results.map(result => {
                const cloneResult = cloneDeep(result);
                cloneResult.value *= coeff;
                if (cloneResult.ranges !== null) {
                    if (cloneResult.ranges.min !== null)
                        cloneResult.ranges.min *= coeff;
                    if (cloneResult.ranges.max !== null)
                        cloneResult.ranges.max *= coeff;
                }
                cloneResult.unit = this.selectedUnit.name;
                cloneResult.unit_id = this.selectedUnit.id;
                return cloneResult;
            });
            return convertedReslults;
        }
        else
            return results;
    }
    get units() {
        return this.$store.state.staticVariables.units;
    }
    countColor(result) {
        const green = '#63C58A';
        const red = '#FF7C7C';
        const value = result.value;
        const min = result?.ranges?.min || 0;
        const max = result?.ranges?.max || 0;
        if (value < min || value > max) {
            return red;
        }
        return green;
    }
    countRanges(result) {
        const { ranges } = result;
        return ranges?.min !== null && ranges?.max !== null
            ? `${ranges?.min} - ${ranges?.max}`
            : ranges?.min !== null
                ? `${ranges?.min} >`
                : `< ${ranges?.max}`;
    }
    editAnalyzes(data) {
        this.editData = { ...data, biomarker: this.results.name, available_units: this.results.available_units };
        this.editAnalyzesModalState = true;
        this.toggleMobileActionsMenu(null);
    }
    toggleMobileActionsMenu(index) {
        this.showMobileActionsMenu = index;
    }
    editModalClose(value) {
        this.editAnalyzesModalState = value;
    }
    addComment(data) {
        this.editData = { ...data, biomarker: this.results.name, available_units: this.results.available_units };
        bus.$emit(IAnalyzes.BusEvents.OPEN_ADD_ANALYZES_COMMENT, data.id);
        this.toggleMobileActionsMenu(null);
    }
    addCommentClose(value) {
        this.addCommentModalState = value;
    }
    openAnalyzeForm(formProp, withFocusOnComment) {
        this.formProp = formProp;
        this.isOpenAnalyzeForm = true;
        this.withFocusOnComment = withFocusOnComment;
    }
    closeAnalyzeForm() {
        this.formProp = null;
        this.isOpenAnalyzeForm = false;
        this.withFocusOnComment = null;
    }
    showDeleteModal(data) {
        this.isShowConfirmationModal = true;
        this.dataConfirmationModal = data;
    }
    async deleteAddedAnalyze() {
        const res = await deleteResult({ id: this.dataConfirmationModal.id });
        const responseUpdatedResults = await this.$store.dispatch('analyzes/getBiomarkerResults', {
            biomarkerId: this.$route.params.id,
            start_date: new Date('01/01/1970'),
            end_date: new Date(),
        });
        this.isShowConfirmationModal = false;
        if (responseUpdatedResults.results.length === 0) {
            this.$router.push({ path: `/analyzes` });
        }
        else {
            this.$emit('refreshBiomarkers');
        }
    }
};
__decorate([
    Prop()
], HistoryAnalyzes.prototype, "results", void 0);
__decorate([
    Prop()
], HistoryAnalyzes.prototype, "selectedUnit", void 0);
__decorate([
    Prop()
], HistoryAnalyzes.prototype, "availableUnits", void 0);
HistoryAnalyzes = __decorate([
    Component({
        components: { MainBtn, AnalyzeItem, EditAnalyzes, AddComment, MessageModal, ConfirmationModal, AnalyzeForm },
    })
], HistoryAnalyzes);
export default HistoryAnalyzes;
