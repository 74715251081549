<template lang="pug">
MessageModal(
  v-bind="$attrs"
  v-on="$listeners"
  size="lg"
  hideOkButton
  title="Патологией обозначается результат, отклоняющийся от нормы")
  template(#body): .pathology-modal__content
    .pathology-modal__col._norm
      .pathology-modal__title._norm Не патология
      .pathology-modal__item._norm(v-for="item in normResults") {{item.value}}
    .pathology-modal__col._out_of_norm
      .pathology-modal__title._out_of_norm Патология
      .pathology-modal__item._out_of_norm(v-for="item in outOfNormResults") {{item.value}}

</template>

<script>
import MessageModal from '@/components/modals_new/templates/MessageModal.vue';
export default {
  components: {MessageModal},
  props: ['biomarkerEnumValues'],
  computed: {
    normResults(){
      return this.biomarkerEnumValues.filter(item => item.is_pathology === false)
    },
    outOfNormResults(){
      return this.biomarkerEnumValues.filter(item => item.is_pathology === true)
    }
  }
}
</script>

<style lang="scss">
.pathology-modal__content {
  display: flex;
  gap: 30px;
  @include media-down($breakpoint-md) {
    flex-direction: column
  }
}
.pathology-modal__col {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 200px;
  text-align: left;
}
.pathology-modal__title {
  margin-bottom: 4px;
  font-weight: 500;
  &._norm{
    color: $positive;
  }
  &._out_of_norm{
    color: $negative;
  }
}
.pathology-modal__item {
  display: flex;
  &:before {
    content: '•';
    margin: 0 7px;
  }
  font-size: 12px;
  border: 1px solid $light-stroke;
  border-radius: 16px;
  padding: 5px;
}
</style>