import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import CommentExpansion from '@/components/UI/CommentExpansion.vue';
import AnalyzeForm from '@/components/modals_new/complex/AnalyzeForm.vue';
import InfoCard from '@/views/AnalyzePage/InfoCard.vue';
import MessageModal from '@/components/modals_new/templates/MessageModal.vue';
import TakeAnalysis from './TakeAnalysis.vue';
let InfoHeader = class InfoHeader extends Vue {
    constructor() {
        super(...arguments);
        this.isCommentsShow = true;
        this.showTooltip = false;
        this.isOpenAnalyzeForm = false;
        this.isOpenModalRefsInfo = false;
    }
    get countChangeValue() {
        if (!this.biomarker)
            return null;
        const value = this.biomarker.results[0].value;
        const prevValue = this.biomarker.results[1].value;
        return Math.abs(value - prevValue).toFixed(2);
    }
    get lastResult() {
        if (!this.biomarker)
            return null;
        const results = [...this.biomarker.results];
        const len = results?.length;
        if (len) {
            return results[0];
        }
        else
            return null;
    }
    get beforeLastResult() {
        if (!this.biomarker)
            return null;
        const results = [...this.biomarker.results];
        const len = results?.length;
        let result = null;
        if (len >= 2)
            result = results[1];
        // if (this.isEnum) result = this.getEnumValue(result.value)
        return result;
    }
    get beforeLastResultValue() {
        return !this.isEnum ? this.beforeLastResult.value : this.getEnumValue(this.beforeLastResult.value).value;
    }
    countColor(result) {
        const { range_zone } = result;
        const classObject = {
            ['text-green']: range_zone === 'in_norm',
            ['text-yellow']: range_zone === 'border_zone',
            ['text-red']: range_zone === 'out_of_norm'
        };
        return classObject;
    }
    get lastValue() {
        if (this.lastResult?.value !== null) {
            return this.isEnum ? this.getEnumValue(this.lastResult?.value) : this.lastResult?.value;
        }
        else
            return 'nodata';
    }
    getEnumValue(value) {
        return this.biomarker.enum_values.find((item) => item.id === value);
    }
    get isShowPrevResult() {
        const { lastResult, beforeLastResult } = this;
        return !!(lastResult && beforeLastResult && lastResult.value !== beforeLastResult.value);
    }
    get infoArrowClass() {
        return {
            'info-item-arrow-icon--top': this.lastResult.value > this.beforeLastResult.value,
            'info-item-arrow-icon--bottom': this.lastResult.value < this.beforeLastResult.value,
        };
    }
    getRanges(ranges) {
        if (!ranges)
            return '';
        return ranges?.min !== null && ranges?.max !== null
            ? `${ranges.min} - ${ranges.max}`
            : ranges.min !== null
                ? `${ranges.min} >`
                : `< ${ranges.max}`;
    }
    scrollToHistory() {
        const top = document.querySelector('.analyzes-page-last-analyzes').offsetTop;
        window.scrollTo({
            top,
            behavior: 'smooth'
        });
    }
    get yourNormText() {
        const { age, gender } = this.$store.state.personalArea.medicalCard;
        const allGenders = this.$store.state.staticVariables.staticVariables.genders;
        const genderText = allGenders.find(item => item.value === gender).description;
        return `${genderText}, ${age} лет`;
    }
    get lastResultEnum() {
        return this.isEnum ? this.getEnumValue(this.lastResult?.value)?.value : null;
    }
    get beforeLastResultEnum() {
        return this.isEnum ? this.getEnumValue(this.beforeLastResult?.value)?.value : null;
    }
};
__decorate([
    Prop()
], InfoHeader.prototype, "availableUnits", void 0);
__decorate([
    Prop()
], InfoHeader.prototype, "biomarker", void 0);
__decorate([
    Prop()
], InfoHeader.prototype, "isOneResult", void 0);
__decorate([
    Prop()
], InfoHeader.prototype, "isEnum", void 0);
__decorate([
    Prop()
], InfoHeader.prototype, "showConvertation", void 0);
__decorate([
    Prop()
], InfoHeader.prototype, "selectedUnit", void 0);
InfoHeader = __decorate([
    Component({
        components: { AnalyzeForm, CommentExpansion, InfoCard, MessageModal, TakeAnalysis },
    })
], InfoHeader);
export default InfoHeader;
