<template lang="pug">
  MessageModal(
    v-bind="$attrs"
    v-on="$listeners"
    wideButtons)
    template(#subtitle): .change-unit-modal__subtitle
      .text-grey-1 {{title}}
      .text-grey-3 Обратите внимание, что при смене единицы измерения она изменится для всех результатов этого показателя
    template(#body): .change-unit-modal__list
      label.change-unit-modal__item(
        v-for="unit in options" 
        :key="unit.id"
        :for="unit.id"
        :class="{ _selected: checkboxValue == unit}"
        :style="{order: unit.coeff === 1 ? 0 : 1}")
        CheckboxInput(
          @input="checkboxValue = unit"
          :value="checkboxValue == unit"
          :id="unit.id" 
          :label="unit.name")
        .change-unit-modal__jlab-value(v-if="unit.coeff === 1") (Рекомендуется)
    template(#buttons)
      BaseBtn(size="sm" @click="$emit('change', checkboxValue)") Изменить
</template>

<script>
import MessageModal from '@/components/modals_new/templates/MessageModal.vue';
import CheckboxInput from '@/components/UI/inputs/CheckboxInput.vue';

export default {
  components: {
    MessageModal,
    CheckboxInput
  },
  data(){
    return {
      checkboxValue: this.options.find( item => item.coeff === 1)
    }
  },
  props: ['options', 'title']
}
</script>

<style lang="scss">

.change-unit-modal__subtitle {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.change-unit-modal__list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;
  @include media-up($breakpoint-md){
    width: 360px;
  }
}

.change-unit-modal__item {
  display: flex;
  align-items: center;
  color: $grey-3;
  gap: 10px;
  cursor: pointer;
  padding: 11px;
  border-radius: 10px;
  &._selected {
    background: $light-background;
  }
}

.change-unit-modal__jlab-value {
  color: $grey-4
}
</style>