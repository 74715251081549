import { __decorate } from "tslib";
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import CheckboxInput from '@/components/UI/inputs/CheckboxInput.vue';
import { rounding } from '@/utils/count-helpers.js';
let ChooseSpecificDates = class ChooseSpecificDates extends Vue {
    constructor() {
        super(...arguments);
        this.rounding = rounding;
    }
    changeVisible(e, id) {
        return { e, id };
    }
    countColor(result) {
        // console.log(result) 
        // const green = '#63C58A';
        // const red = '#FF7C7C';
        // const value = result.value;
        // const min = result?.ranges?.min || 0;
        // const max = result?.ranges?.max || 0;
        // if (value < min || value > max) {
        //   return red;
        // }
        const colorObj = {
            in_norm: '#63C58A',
            out_of_norm: '#FF7C7C',
            border_zone: '#FFDE79',
            null: 'purple'
        };
        return colorObj[result.range_zone];
    }
    get allVisible() {
        return this.results.every(result => {
            return result.visible === true;
        });
    }
};
__decorate([
    Prop()
], ChooseSpecificDates.prototype, "results", void 0);
__decorate([
    Emit('change-visible')
], ChooseSpecificDates.prototype, "changeVisible", null);
ChooseSpecificDates = __decorate([
    Component({
        components: { CheckboxInput },
    })
], ChooseSpecificDates);
export default ChooseSpecificDates;
