import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
let LaboratoryDesignation = class LaboratoryDesignation extends Vue {
    constructor() {
        super(...arguments);
        this.designationList = [
            {
                text: 'ЛабСтори',
                icon: 'circle-icon',
            },
            {
                text: 'другая лаборатория',
                icon: 'star-icon',
            },
        ];
    }
};
LaboratoryDesignation = __decorate([
    Component({})
], LaboratoryDesignation);
export default LaboratoryDesignation;
