import { __decorate } from "tslib";
import { Component, Emit, Vue, Watch } from 'vue-property-decorator';
import { periodList } from '@/utils/utils';
import LockLabel from '@/components/LockLabel.vue';
let PeriodsComponent = class PeriodsComponent extends Vue {
    constructor() {
        super(...arguments);
        this.picked = 0;
    }
    created() {
        this.picked = this.selectedPeriod;
    }
    setPicked(val) {
        this.picked = val;
    }
    get periodList() {
        return periodList;
    }
    get selectedPeriod() {
        return this.$store.state.filter.selectedPeriod;
    }
    changeDateValue() {
        this.$store.commit('filter/setPropertyInStore', { name: 'selectedPeriod', value: this.picked });
        return this.picked;
    }
};
__decorate([
    Watch('selectedPeriod')
], PeriodsComponent.prototype, "setPicked", null);
__decorate([
    Emit('change-period')
], PeriodsComponent.prototype, "changeDateValue", null);
PeriodsComponent = __decorate([
    Component({ components: { LockLabel } })
], PeriodsComponent);
export default PeriodsComponent;
