import { __decorate } from "tslib";
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { rounding } from '@/utils/count-helpers.js';
import { areaRadial as d3AreaRadial, axisBottom as d3AxisBottom, axisRight as d3AxisRight, max as d3Max, scaleLinear as d3ScaleLinear, scaleTime as d3ScaleTime, selectAll as d3SelectAll, timeFormat as d3TimeFormat, timeFormatDefaultLocale as d3TimeFormatDefaultLocale, timeMonth as d3TimeMonth, timeYear as d3TimeYear } from 'd3';
import { format } from 'date-fns';
import { langConfig, points } from '@/utils/utils';
import ruLocale from "date-fns/locale/ru";
let ChartComponent = class ChartComponent extends Vue {
    constructor() {
        super(...arguments);
        this.rounding = rounding;
        this.margin = {
            left: 50,
            right: 50,
            bottom: 40,
            top: 0,
        };
        // TEMP
        this.resultsX = [];
    }
    mounted() {
        this.init();
    }
    calculateResultsX() {
        const resultsX = [...this.results];
        const { x, prettyDate } = this;
        resultsX.forEach(item => {
            item.x = x(prettyDate(item.date));
        });
        this.shiftResultsX(resultsX);
    }
    shiftResultsX(resultsX) {
        let repeatIterations = 0;
        const sortFunc = (a, b) => a.x - b.x;
        resultsX.sort(sortFunc);
        for (let i = 0; i < resultsX.length; i++) {
            if (i === 0)
                continue;
            const current = resultsX[i].x;
            const prev = resultsX[i - 1].x;
            const diff = prev - current;
            const cond = diff < 15 && diff > -15;
            // console.log(repeatIterations, prev, current, diff, cond ,'' + resultsX[i].value)
            if (cond) {
                resultsX[i].x = prev + 17;
                if (repeatIterations < 10) {
                    repeatIterations += 1;
                    resultsX.sort(sortFunc);
                    i = 0;
                }
            }
        }
        this.resultsX = resultsX;
    }
    shit(d) {
        const curr = new Date(d.date);
        const start = new Date(this.dateRange[0]);
        return curr > start;
    }
    get viewBox() {
        return `0 0 ${this.width} ${this.height}`;
    }
    get innerWidth() {
        return this.width - this.margin.left - this.margin.right;
    }
    get innerHeight() {
        return this.height - this.margin.top - this.margin.bottom;
    }
    get valueArray() {
        const valueArray = [];
        this.results.forEach((item) => {
            valueArray.push(item.value);
            if (item.ranges?.max !== null) {
                valueArray.push(item.ranges?.max);
            }
        });
        return valueArray;
    }
    get maxValue() {
        return d3Max(this.valueArray) || 0;
    }
    get YMaxValue() {
        return this.maxValue + (this.maxValue / 100) * 20;
    }
    get rangeX() {
        return [0, this.innerWidth];
    }
    get domainX() {
        return this.dateRange;
    }
    get x() {
        this.setLocale();
        return d3ScaleTime().rangeRound(this.rangeX).domain(this.domainX);
    }
    get rangeY() {
        return [this.innerHeight, 0];
    }
    get domainY() {
        return [0, this.YMaxValue];
    }
    get y() {
        return d3ScaleLinear().range(this.rangeY).domain(this.domainY);
    }
    get countDStar() {
        const radialAreaGenerator = d3AreaRadial()
            .angle((d) => {
            return d[0];
        })
            .outerRadius((d) => {
            return d[1];
        });
        return radialAreaGenerator(points);
    }
    countTooltipDate(d) {
        return format(new Date(d.date), 'd MMMM yyyy', { locale: ruLocale });
    }
    countXTextRefZone(idx) {
        switch (idx) {
            case 0:
                return -24;
            case 1:
                return this.innerWidth + 5;
            case 2:
                return -24;
            case 3:
                return this.innerWidth + 5;
        }
    }
    countYTextRefZone(idx) {
        const results = [...this.results];
        if (!results.length) {
            return 0;
        }
        switch (idx) {
            case 0:
                return !results[0]?.ranges || results[0]?.ranges?.max === null ? 0 : this.y(results[0].ranges?.max) + 3;
            case 1:
                return this.y(results[results.length - 1].ranges?.max || 0) + 3;
            case 2:
                return !results[0]?.ranges || results[0].ranges?.min === null ? 0 : this.y(results[0].ranges?.min) + 3;
            case 3:
                return results[results.length - 1].ranges?.min === null
                    ? 0
                    : this.y(results[results.length - 1].ranges?.min || 0) + 3;
        }
    }
    countTextRefZone(idx) {
        const results = [...this.results];
        if (!results.length) {
            return '';
        }
        switch (idx) {
            case 0:
                return rounding(results[0].ranges?.max, 0);
            case 1:
                return rounding(results[results.length - 1].ranges?.max);
            case 2:
                return rounding(results[0].ranges?.min);
            case 3:
                return rounding(results[results.length - 1].ranges?.min);
        }
    }
    countRefX1(idx) {
        return this.innerWidth;
        const results = [...this.results.reverse()];
        const resultItem = results[idx];
        const nextResultItem = results[idx + 1];
        if (nextResultItem) {
            if (resultItem.ranges?.max !== nextResultItem.ranges?.max ||
                resultItem.ranges?.min !== nextResultItem.ranges?.min) {
                return this.x(this.prettyDate(nextResultItem.date)) - 10;
            }
            else {
                return this.x(this.prettyDate(nextResultItem.date));
            }
        }
        else {
            return this.innerWidth;
        }
    }
    countRefX2(idx) {
        return 0;
        const results = [...this.results.reverse()];
        const resultItem = results[idx];
        const prevResultItem = results[idx - 1];
        if (prevResultItem) {
            if (resultItem.ranges?.max !== prevResultItem.ranges?.max ||
                resultItem.ranges?.min !== prevResultItem.ranges?.min) {
                return this.x(this.prettyDate(resultItem.date)) - 10;
            }
            else {
                return this.x(this.prettyDate(resultItem.date));
            }
        }
        else {
            return 0;
        }
    }
    countColor(d) {
        const green = '#63C58A';
        const red = '#FF7C7C';
        const yellow = '#FFDE79';
        const value = d.value;
        const min = d.ranges?.min || 0;
        const max = d.ranges?.max || 0;
        if (value < min || value > max) {
            return red;
            // } else if (value < min + (min / 100) * 10 || value > max - (max / 100) * 10) {
        }
        else if (value === min || value === max) {
            return yellow;
        }
        else {
            return green;
        }
    }
    prettyDate(date) {
        return new Date(date);
    }
    setLocale() {
        d3TimeFormatDefaultLocale(langConfig);
    }
    initAxisX(tricksCount = 10, formatDate = '%B') {
        d3SelectAll('.x-g').remove();
        const gX = d3SelectAll('.x');
        gX.append('g')
            .attr('class', 'x-g')
            .call(d3AxisBottom(this.x).ticks(tricksCount, d3TimeFormat(formatDate)));
        // .call(d3AxisBottom(this.x).ticks(d3TimeMonth.every(1), d3TimeFormat('%B')));
        gX.selectAll('line').remove();
        gX.select('.domain').remove();
    }
    reinitAxisX(newRange) {
        const rangeDays = (newRange[1] - newRange[0]) / 864e5;
        const desktop = this.$q.screen.width > 500;
        const axisXTextByDays = {
            0: () => { this.initAxisX(d3TimeMonth.every(1), '%B'); },
            190: () => { this.initAxisX(d3TimeMonth.every(1), '%y'); },
            360: () => { this.initAxisX(d3TimeMonth.every(2), '%m.%y'); },
            720: () => { this.initAxisX(d3TimeYear.every(1), '%Y'); },
        };
        for (const key in axisXTextByDays) {
            if (key < rangeDays) {
                axisXTextByDays[key]();
                // console.log(`${key} < ${rangeDays}`)
            }
        }
        this.calculateResultsX();
    }
    initHorizontalLines() {
        d3SelectAll('.y-horizontal-g').remove();
        const gYHorizontal = d3SelectAll('.y-horizontal');
        gYHorizontal
            .append('g')
            .attr('class', 'y-horizontal-g')
            .call(d3AxisRight(this.y).ticks(3).tickSizeOuter(0).tickSizeInner(this.innerWidth));
        gYHorizontal.selectAll('line').attr('stroke', '#E9E8FF');
        gYHorizontal.selectAll('text').remove();
        gYHorizontal.select('.domain').remove();
    }
    init() {
        this.initAxisX();
        this.initHorizontalLines();
        this.calculateResultsX();
    }
    downloadChart() {
        const svgElement = document.getElementById('dynamicChart');
        const { width, height } = svgElement?.getBBox();
        const clonedSvgElement = svgElement?.cloneNode(true);
        const outerHTML = clonedSvgElement?.outerHTML;
        const blob = new Blob([outerHTML], { type: 'image/svg+xml;charset=utf-8' });
        const URL = window.URL || window.webkitURL || window;
        const blobURL = URL.createObjectURL(blob);
        const image = new Image();
        image.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;
            const context = canvas.getContext('2d');
            context?.drawImage(image, 0, 0, width, height);
            this.downloadImage(canvas);
        };
        image.src = blobURL;
    }
    downloadImage(canvas) {
        const jpeg = canvas.toDataURL('image/jpg');
        this.download(jpeg, 'chart.jpeg');
    }
    download(href, name) {
        const link = document.createElement('a');
        link.download = name;
        link.style.opacity = '0';
        document.body.append(link);
        link.target = '_blank';
        link.href = href;
        link.click();
        link.remove();
    }
    countRanges(result) {
        const { ranges } = result;
        return ranges.min !== null && ranges.max !== null
            ? `${ranges.min} - ${ranges.max}`
            : ranges.min !== null
                ? `${ranges.min} >`
                : `< ${ranges.max}`;
    }
};
__decorate([
    Prop()
], ChartComponent.prototype, "results", void 0);
__decorate([
    Prop()
], ChartComponent.prototype, "dateRange", void 0);
__decorate([
    Prop()
], ChartComponent.prototype, "isRefZonesVisible", void 0);
__decorate([
    Prop({ default: window.screen.width >= 767 ? 702 : 320 })
], ChartComponent.prototype, "width", void 0);
__decorate([
    Prop({ default: window.screen.width >= 767 ? 350 : 160 })
], ChartComponent.prototype, "height", void 0);
__decorate([
    Watch('dateRange')
], ChartComponent.prototype, "reinitAxisX", null);
ChartComponent = __decorate([
    Component({})
], ChartComponent);
export default ChartComponent;
