import { __decorate } from "tslib";
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import MainSelect from '@/components/UI/MainSelect.vue';
import MainTag from '@/components/UI/MainTag.vue';
import LaboratoryDesignation from '@/components/LaboratoryDesignation.vue';
import PeriodsComponent from '@/components/PeriodsComponent.vue';
import MainBtn from '@/components/UI/buttons/MainBtn.vue';
import CheckboxInput from '@/components/UI/inputs/CheckboxInput.vue';
import { subDays, format } from 'date-fns';
import DatePicker from '@/components/UI/DatePicker.vue';
import FreeTariffButton from '@/components/FreeTariffButton.vue';
import LockLabel from '@/components/LockLabel.vue';
let ChartControl = class ChartControl extends Vue {
    constructor() {
        super(...arguments);
        this.selectedValue = '';
        this.isDateModalOpen = false;
        this.periodValue = {
            from: '',
            to: '',
        };
        this.isHide = false;
        this.localSelectedLabs = {};
        this.currentPeriod = '';
    }
    created() {
        this.localSelectedLabs = { ...this.selectedLaboratories };
        this.$store.dispatch('filter/setSelectedLaboratories', this.laboratoryList);
    }
    get selectedLaboratories() {
        return this.$store.state.filter.selectedLaboratories;
    }
    get laboratories() {
        return this.$store.state.filter.laboratoriesList;
    }
    get selectedPeriod() {
        return this.$store.state.filter.selectedPeriod;
    }
    get checkedLabList() {
        const labs = this.laboratories;
        const selected = this.selectedLaboratories;
        const ids = Object.entries(selected)
            .filter((el) => el[1])
            .map((el) => +el[0]);
        const res = [];
        ids.forEach((id) => {
            labs.forEach((el) => {
                if (el.id === id)
                    res.push(el.name);
            });
        });
        return res;
    }
    onCheckChange(id) {
        const list = { ...this.localSelectedLabs };
        list[id] = !list[id];
        if (list.all) {
            for (const prop in list) {
                list[prop] = true;
            }
        }
        this.localSelectedLabs = { ...list };
    }
    setSelectedLabs() {
        this.$store.commit('filter/setPropertyInStore', { name: 'selectedLaboratories', value: this.localSelectedLabs });
        this.inputSelect();
    }
    removeSelectedLab(labId) {
        this.onCheckChange(labId);
        this.setSelectedLabs();
    }
    toggleDateModal(val) {
        this.isDateModalOpen = val;
    }
    changePeriod(obj) {
        this.$store.commit('filter/setPropertyInStore', { name: 'selectedPeriod', value: 4 });
        this.currentPeriod = `с ${format(new Date(obj.from), 'dd.MM.yyyy')} по ${format(new Date(obj.to), 'dd.MM.yyyy')}`;
        return [subDays(new Date(obj.from), 0), subDays(new Date(obj.to), 0)];
    }
    changeRadioDateId(index) {
        switch (index) {
            case 0:
                return [subDays(new Date(), 30), new Date()];
            case 1:
                return [subDays(new Date(), 90), new Date()];
            case 2:
                return [subDays(new Date(), 180), new Date()];
            case 3:
                return [subDays(new Date(), 365), new Date()];
            case 4:
                return [new Date(`${this.firstYear}-01-01`), new Date()];
        }
    }
    inputSelect() {
        const selected = { ...this.selectedLaboratories };
        this.onSelect();
        return selected;
    }
    onSelect() {
        this.isHide = false;
    }
    applyChanges() {
        const selected = { ...this.selectedLaboratories };
        return selected;
    }
};
__decorate([
    Prop()
], ChartControl.prototype, "laboratoryList", void 0);
__decorate([
    Prop()
], ChartControl.prototype, "laboratoryOptions", void 0);
__decorate([
    Prop()
], ChartControl.prototype, "firstYear", void 0);
__decorate([
    Emit('change-date-range')
], ChartControl.prototype, "changePeriod", null);
__decorate([
    Emit('change-date-range')
], ChartControl.prototype, "changeRadioDateId", null);
__decorate([
    Emit('choose-laboratory')
], ChartControl.prototype, "inputSelect", null);
__decorate([
    Emit('select')
], ChartControl.prototype, "onSelect", null);
__decorate([
    Emit('apply-changes')
], ChartControl.prototype, "applyChanges", null);
ChartControl = __decorate([
    Component({
        components: { MainBtn, PeriodsComponent, LaboratoryDesignation, MainSelect, CheckboxInput, DatePicker, MainTag, FreeTariffButton, LockLabel },
    })
], ChartControl);
export default ChartControl;
