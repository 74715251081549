import { __decorate } from "tslib";
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import MainBtn from '@/components/UI/buttons/MainBtn.vue';
import CheckboxInput from '@/components/UI/inputs/CheckboxInput.vue';
import BackBtn from '@/components/UI/buttons/BackBtn.vue';
let FourthType = class FourthType extends Vue {
    constructor() {
        super(...arguments);
        this.localResults = [];
    }
    mounted() {
        for (const item of this.results) {
            this.localResults.push({ ...item });
        }
    }
    changeVisible(e, id) {
        this.localResults = this.localResults.map((el) => {
            if (el.id === id)
                return { ...el, visible: e };
            return el;
        });
        this.$store.commit('filter/setPropertyInStore', { name: 'selectedVisibleResults', value: this.localResults });
    }
    countColor(result) {
        console.log(result);
        const green = '#63C58A';
        const red = '#FF7C7C';
        const value = result.value;
        const min = result.ranges.min || 0;
        const max = result.ranges.max || 0;
        if (value < min || value > max) {
            return red;
        }
        return green;
    }
    applyChanges() {
        return this.localResults;
    }
    changeType() {
        return 0;
    }
};
__decorate([
    Prop()
], FourthType.prototype, "results", void 0);
__decorate([
    Emit('change-visible')
], FourthType.prototype, "applyChanges", null);
__decorate([
    Emit('change-type')
], FourthType.prototype, "changeType", null);
FourthType = __decorate([
    Component({
        components: { BackBtn, CheckboxInput, MainBtn },
    })
], FourthType);
export default FourthType;
