<template lang="pug">
.info-card(v-if="value")
  .info-card__title {{title}}
  .info-card__field(v-if="value.date") {{$date(new Date(value.date), 'd MMMM yyyy')}}
  .info-card__value-wrapper(:class="classObject")
    .info-card__value(:class="classObject") {{computedValue}}
    .info-card__unit(v-if="!isEnum") {{selectedUnit.name}}
  .q-py-sm(v-if="isEnum && (value.num_value || value.rel_value)")
    .info-card__field(v-if="value.num_value") Количественное: {{value.num_value}}
      span(v-if="value.power_value") ^{{value.power_value}}
    .info-card__field(v-if="value.rel_value") Относительное: {{value.rel_value}}
      span(v-if="value.power_value")
  .info-card__refs(v-if="!isEnum") Реф. нормы: {{rangeSelected}}, {{selectedUnit.name}}
  .info-card__lab Лаборатория: {{value.laboratory}}
  .analyze-item__pathology(v-if="isEnum")
    //- BaseBtn.q-mt-md(@click="isOpenPathologyModal = true" size="xs" type="light" icon="doc-question-icon" :iconSize="20") {{value.is_pathology ?  'Результат является патологией' : 'Результат не является патологией'}}
    BaseBtn.q-mt-md(@click="isOpenPathologyModal = true" size="xs" type="light" icon="doc-question-icon" :iconSize="20") {{value.range_zone === 'out_of_norm' ?  'Результат является патологией' : 'Результат не является патологией'}}
    PathologyModal(v-model="isOpenPathologyModal" :biomarkerEnumValues="biomarkerEnumValues")
  template(v-if="isShowLastUnitTip")
    InfoButton(@click="isOpenConvertModal = true") {{value.initial_value}} {{value.last_unit}}
    ConvertModal2(
      v-model="isOpenConvertModal"
      :range_zone="value.range_zone"

      :prevValue="value.initial_value"
      :prevUnit="value.last_unit"
      :prevRange="rangeOld"

      :newValue="value.value * selectedUnit.coeff"
      :newUnit="selectedUnit.name"
      :newRange="rangeSelected")
</template>

<script>
import ConvertModal2 from '@/components/modals_new/filled/ConvertModal2.vue';
import PathologyModal from '@/components/modals_new/filled/PathologyModal.vue';
import InfoButton from '@/views/AnalyzePage/InfoButton.vue';
import {getRangeText, rounding} from '@/utils/count-helpers.js';

export default {
  data(){
    return {
      isOpenConvertModal: false,
      isOpenPathologyModal: false
    }
  },
  components: {InfoButton, ConvertModal2, PathologyModal},
  props: ['isEnum', 'value', 'title', 'selectedUnit', 'enumValue', 'biomarkerEnumValues'],
  computed: {
    classObject() {
      // let classObject
      // if (this.isEnum) {
      //   const isPathology = this.value?.is_pathology
      //   classObject = {
      //     ['text-red']: isPathology === true,
      //     ['text-green']: isPathology === false,
      //     ['text-primary']: isPathology === null
      //   }
      // } else {
      const {range_zone} = this?.value;
      const classObject = {
        _enum: true,
        ['text-green']: range_zone === 'in_norm',
        ['text-yellow']: range_zone === 'border_zone',
        ['text-red']: range_zone === 'out_of_norm',
        ['text-primary']: range_zone === null,
      }
      // }
      return classObject
    },
    computedValue(){
      if (this.isEnum) return this.enumValue
      const {value} = this.value;
      const {coeff} = this.selectedUnit
      if (coeff === null) return rounding(value)
      else return rounding(value * coeff)
    },
    rangeSelected(){
      return getRangeText(this.value.ranges, this.selectedUnit?.coeff || 1)
    },
    rangeJlab(){
      return getRangeText(this.value.ranges, 1)
    },
    rangeOld(){
      // TODO
      return getRangeText(this.value.ranges, this.value.initial_coeff)
    },
    isShowLastUnitTip(){
      return (
        !this.isEnum && 
        this.value.unit_id !== this.value.last_unit_id && 
        this.value.last_unit_id !== null &&
        this.selectedUnit.id !== this.value.last_unit_id)
    }
  },

}
</script>

<style lang="scss">
  .info-card {
    padding: 18px 19px;
    width: 300px;
    background: white;
    border-radius: 20px;
    font-size: 12px;
    line-height: 16px;
    color: $grey-1;
    display: flex;
    flex-direction: column;
    @include media-down($breakpoint-md) {
      width: 100%;
    }
  }

  .info-card__title {
    font-size: 14px;
    line-height: 18px;
  }

  .info-card__field {
    margin-top: 4px;
    color: $grey-4;
  }

  .info-card__value-wrapper {
    margin-top: 13px;
    display: flex;
    align-items: baseline;
    gap: 3px;
  }

  .info-card__value {
    font-weight: 500;
    font-size: 36px;
    line-height: 47px;
    @include media-down($breakpoint-md) {
      font-size: 26px;
      line-height: 1em;
    }
    &._enum {
      font-size: 20px;
      line-height: 25px;
    }
  }

  .info-card__unit {
    font-size: 14px;
    line-height: 18px;
    @include media-down($breakpoint-md) {
      font-size: 12px;
    }
  }

  .info-card__refs {
    margin-top: 4px;
  }

  .info-card__lab {
    margin-top: 6px;
  }


</style>


