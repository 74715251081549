import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import MainBtn from '@/components/UI/buttons/MainBtn.vue';
import { IRouter } from '@/interfaces/router.interface';
let LookDynamic = class LookDynamic extends Vue {
    goToDynamicAnalyzesPage() {
        this.$router.push({ name: IRouter.ROUTE_NAME.DYNAMICS_ANALYZES, query: { ids: this.$route.params.id } });
    }
};
LookDynamic = __decorate([
    Component({
        components: { MainBtn },
    })
], LookDynamic);
export default LookDynamic;
