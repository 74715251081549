import { __decorate } from "tslib";
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
let MainToggle = class MainToggle extends Vue {
    changeValue(val) {
        return val;
    }
    get isLabstoryPatient() {
        return this.$store.state.auth.isLabstoryPatient;
    }
    set isLabstoryPatient(value) {
        this.$store.commit('auth/setPropertyInStore', { name: 'isLabstoryPatient', value });
    }
};
__decorate([
    Prop()
], MainToggle.prototype, "label", void 0);
__decorate([
    Emit('change-value')
], MainToggle.prototype, "changeValue", null);
MainToggle = __decorate([
    Component({})
], MainToggle);
export default MainToggle;
