<template lang="pug">
MessageModal(
  :value="value"
  @input="$emit('input', false); "
  title="Мы конвертировали вашу единицу измерения")
  template(#subtitle)
    .flex.items-center.q-gutter-sm
      .message-modal__tip {{prevUnit || 'nodata'}}
      icon(name="next-icon3" color="#7C74E9" width="24px" height="24px")
      .message-modal__tip {{newUnit || 'nodata'}}
  template(#body).
    Чтобы дать вам возможность отслеживать динамику биомаркера, мы автоматически переводим значение и рефересные зоны в одну единицу измерения.
    Вы загрузили биомаркер в {{prevUnit}}, а мы его автоматически перевели в {{newUnit}}.
    Чуть позже мы добавим возможность просматривать показатели в любой удобной для вас единице измерения 👌
</template>
<script>
import MessageModal from '@/components/modals_new/templates/MessageModal.vue';

export default {
  components: {MessageModal},
  props:['value', 'prevUnit', 'newUnit']
//  TODO прееместить из компонентов логиуку сюда

}
</script>
