import { __decorate } from "tslib";
import { Component, Emit, Vue, Prop } from 'vue-property-decorator';
import MainBtn from '@/components/UI/buttons/MainBtn.vue';
import { mobilePeriodList } from '@/utils/utils';
let MainType = class MainType extends Vue {
    constructor() {
        super(...arguments);
        this.mainItemList = ['Выберите лабораторию', 'Выберите период'];
    }
    get periodList() {
        return mobilePeriodList;
    }
    get selectedPeriod() {
        return this.$store.state.filter.selectedPeriod;
    }
    get periodValue() {
        return this.$store.state.filter.selectedPeriodDateRange;
    }
    get selectedLaboratories() {
        return this.$store.state.filter.selectedLaboratories;
    }
    get laboratoriesList() {
        return this.$store.state.filter.laboratoriesList;
    }
    get selectedLabs() {
        const allLabs = [...this.laboratoriesList];
        const selectedLabs = { ...this.selectedLaboratories };
        const res = [];
        for (const prop in selectedLabs) {
            if (selectedLabs[prop] && prop !== 'all') {
                res.push(allLabs.find((el) => el.id == prop));
            }
        }
        return res;
    }
    clickItem(index) {
        return index + 1;
    }
};
__decorate([
    Prop()
], MainType.prototype, "results", void 0);
__decorate([
    Emit('change-type')
], MainType.prototype, "clickItem", null);
MainType = __decorate([
    Component({
        components: { MainBtn },
    })
], MainType);
export default MainType;
