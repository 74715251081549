import { __decorate } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import { isAfter, isBefore, subDays, format } from 'date-fns';
import { userFirstVisit, someOldUnitNotEqualUnit } from '@/utils/utils';
import ChartComponent from '@/components/ChartComponent.vue';
import ResultsChart from '@/components/chart/ResultsChart.vue';
import CheckboxInput from '@/components/UI/inputs/CheckboxInput.vue';
import LaboratoryDesignation from '@/components/LaboratoryDesignation.vue';
import BackBtn from '@/components/UI/buttons/BackBtn.vue';
import ChartMobileFilter from '@/components/modals/ChartMobileFilter/ChartMobileFilter.vue';
import MainToggle from '@/components/UI/MainToggle.vue';
import BaseBadge from '@/components/UI/BaseBadge.vue';
import ConvertModal from '@/components/modals_new/filled/ConvertModal.vue';
import InfoHeader from '@/views/AnalyzePage/InfoHeader.vue';
import ChooseSpecificDates from '@/views/AnalyzePage/ChooseSpecificDates.vue';
import ChartControl from '@/views/AnalyzePage/ChartControl.vue';
import HistoryAnalyzes from '@/views/AnalyzePage/HistoryAnalyzes.vue';
import LookDynamic from '@/views/AnalyzePage/LookDynamic.vue';
import { showServerError } from '@/utils/showNotify.js';
import LoadingSpinner from '@/components/UI/LoadingSpinner.vue';
import MessageModal from '@/components/modals_new/templates/MessageModal.vue';
import ChangeUnitModal from '@/components/modals_new/filled/ChangeUnitModal.vue';
import { getBiomarkerGroupResults, getBiomarkerResults } from '@/api/analyzes.js';
// TODO instore
const dateGap = window.screen.width >= 767 ? 180 : 90;
let AnalyzePage = class AnalyzePage extends Vue {
    constructor() {
        super(...arguments);
        this.isRefZonesVisible = true;
        this.isRefZonesCheckboxVisible = true;
        this.dateRange = [subDays(new Date(), dateGap), new Date()];
        this.laboratoryList = [];
        this.isFilterOpen = false;
        this.biomarkerResults = { id: 0, name: '', ranges: { min: 0, max: 0 }, results: [] };
        this.data = JSON.parse(JSON.stringify(this.biomarkerResults));
        this.loading = false;
        this.isOpenConvertModal = false;
        this.prevUnit = 'nodata';
        this.newUnit = 'nodata';
        this.biomarkerGroupResults = null; //новый старые убрать
        this.isOpenChangeUnitModal = false;
        this.selectedUnit = null;
        this.jlabUnit = null;
        this.oldUnit = null;
    }
    async created() {
        try {
            this.loading = true;
            // const responseData = {
            //   biomarkerId: this.$route.params.id,
            //   start_date: this.dateRange[0],
            //   end_date: new Date(),
            // };
            const res = await Promise.all([
                // this.$store.dispatch('analyzes/getBiomarkerResults', responseData),
                this.$store.dispatch('analyzes/getBiomarkerResults', {
                    biomarkerId: this.$route.params.id,
                    start_date: new Date('01/01/1970'),
                    end_date: new Date(),
                }),
            ]).catch();
        }
        catch (error) {
            // temp
            showServerError(error);
        }
        finally {
            this.loading = false;
        }
    }
    everyResultRangeAreEqual() {
        const targetArray = this.biomarkerGroupResults?.results;
        const firstResultRange = targetArray?.[0]?.ranges;
        return targetArray?.every(result => {
            const nextResultRanges = result.ranges;
            const { min: nMin, max: nMax } = nextResultRanges;
            const { min: fMin, max: fMax } = firstResultRange;
            return nMin === fMin && nMax === fMax;
        });
    }
    async mounted() {
        await this.check404Error();
        this.checkUntsEquality();
        if (!this.isEnum && !this.everyResultRangeAreEqual()) {
            this.isRefZonesVisible = false;
            this.isRefZonesCheckboxVisible = false;
        }
    }
    async check404Error() {
        const isNotFound = (err) => {
            return err?.errorData?.error_code === 'not_found' || err.errorData.indexOf('Page not found');
        };
        const goToHome = () => {
            this.$router.push({ name: 'indexPage' });
        };
        this.loadBiomarkerGroupResults(isNotFound, goToHome);
    }
    async loadBiomarkerGroupResults(isNotFound, goToHome) {
        try {
            const resGroupResults = await getBiomarkerGroupResults({ biomarker_group_id: this.$route.params.id });
            if (resGroupResults.results.length === 0)
                goToHome();
            else
                this.biomarkerGroupResults = resGroupResults;
        }
        catch (err) {
            if (isNotFound(err)) {
                try {
                    const resResults = await getBiomarkerResults({ biomarker_id: this.$route.params.id });
                    if (resResults.length === 0)
                        goToHome();
                    else
                        this.biomarkerGroupResults = resResults;
                }
                catch (err) {
                    if (isNotFound(err))
                        goToHome();
                }
            }
        }
    }
    checkUntsEquality() {
        const { notEqual, oldUnit, jlabUnit } = someOldUnitNotEqualUnit(this.biomarkerGroupResults?.results, this.biomarkerGroupResults?.available_units);
        if (notEqual) {
            this.prevUnit = oldUnit?.name;
            this.newUnit = jlabUnit?.name;
            this.isOpenConvertModal = userFirstVisit({ localStorageItem: 'lastUnitAnalyzes', id: this.$route.params.id });
        }
    }
    function(val) {
        this.biomarkerResults = val;
        this.data = val;
        this.countAllLaboratory;
        // TODO ищу по имени - нет айди старого юнита в массиве availableUnits
        this.oldUnit = this.availableUnits.find(unit => unit?.name === val.unit);
        this.jlabUnit = this.availableUnits.find(unit => unit.coeff === 1);
        this.selectedUnit = this.jlabUnit ||
            {
                name: val.unit,
                id: val.unit_id,
                coeff: null
            };
    }
    setData(val) {
        this.biomarkerResults.results = val;
    }
    get analyzeResults() {
        return this.$store.state.analyzes.biomarkerResults;
    }
    get filterVisibleResults() {
        const results = this.biomarkerResults?.results.filter((result) => result.visible === true);
        return results;
    }
    get filterDateResults() {
        const filteredResults = [];
        this.biomarkerResult.results?.forEach((result) => {
            if (isBefore(subDays(new Date(result.date), 0), this.dateRange[1]) &&
                isAfter(subDays(new Date(result.date), 0), this.dateRange[0])) {
                filteredResults.push(result);
            }
        });
        if (this.data) {
            this.data.results = filteredResults;
            return this.data.results;
        }
    }
    get countAllLaboratory() {
        const laboratoryList = [];
        const map = new Map();
        for (const result of this.biomarkerResult?.results) {
            if (!map.has(result.laboratory_id)) {
                map.set(result.laboratory_id, true);
                laboratoryList.push({
                    id: result.laboratory_id,
                    name: result.laboratory,
                });
            }
        }
        this.laboratoryList = [...laboratoryList];
        this.$store.dispatch('filter/setSelectedLaboratories', laboratoryList);
        return null;
    }
    get biomarkerResult() {
        return this.$store.getters['analyzes/getBiomarkerResults'];
    }
    get allResults() {
        return this.$store.getters['analyzes/getAllBiomarkerResults'];
    }
    get laboratories() {
        return this.$store.state.filter.laboratoriesList;
    }
    get selectedDateRange() {
        return this.$store.state.filter.selectedPeriodDateRange;
    }
    get selectedVisibleResults() {
        return this.$store.state.filter.selectedVisibleResults;
    }
    changeRefZonesVisible(val) {
        this.isRefZonesVisible = val;
    }
    changeVisible(obj) {
        const { e, id } = obj;
        this.biomarkerResults.results[id].visible = e;
    }
    toggleAll(allVisible) {
        this.biomarkerResults.results.forEach((result) => {
            result.visible = !allVisible;
        });
    }
    changeMobileVisible(arr) {
        this.data.results = [];
        for (const item of arr) {
            this.data.results.push({ ...item });
        }
    }
    toggleFilterModal(val) {
        this.isFilterOpen = val;
    }
    downloadChart() {
        this.$refs.chart.downloadChart();
    }
    async changeDateRange(val) {
        const [start_date, end_date] = val;
        await this.$store.dispatch('analyzes/getBiomarkerResults', {
            biomarkerId: this.$route.params.id,
            start_date,
            end_date,
        });
        this.dateRange = val;
    }
    filterLaboratoryResults() {
        console.log(this.laboratoryList);
        if (!this.laboratoryList.length) {
            this.data.results = [...this.allResults.results];
        }
        else {
            this.data.results = this.allResults?.results.filter((result) => {
                return this.laboratoryList.some((laboratory) => {
                    return result.laboratory_id === laboratory.id;
                });
            });
        }
    }
    async setFilter(val) {
        const ids = [];
        const labs = [];
        for (const prop in val) {
            if (val[prop])
                ids.push(+prop);
        }
        this.laboratories.forEach((el) => {
            for (const id of ids) {
                if (el.id === id)
                    labs.push(el);
            }
        });
        this.laboratoryList = [...labs];
        this.filterLaboratoryResults();
    }
    async setMobileFilter(value) {
        try {
            await this.changeDateRange(this.selectedDateRange);
            await this.setFilter(value);
            await this.changeMobileVisible(this.selectedVisibleResults);
        }
        catch (error) {
            throw Error(error);
        }
    }
    get isOneResult() {
        return this.biomarkerGroupResults?.results.length === 1;
    }
    get isEnum() {
        return this.biomarkerGroupResults?.type === 'enum';
    }
    selectUnit($event) {
        this.selectedUnit = $event;
        this.isOpenChangeUnitModal = false;
    }
    get availableUnits() {
        return this.biomarkerResults.available_units;
    }
    get clientAvailableUnits() {
        const clientIdUnits = this.biomarkerResults.results.map(item => item.last_unit_id);
        clientIdUnits.push(this.biomarkerResults.unit_id);
        return this.availableUnits.filter(item => clientIdUnits.includes(item.id));
    }
    get showConvertation() {
        return !this.isEnum && this.clientAvailableUnits.length > 1;
    }
    get firstYear() {
        const dates = this.biomarkerGroupResults?.results.map(item => +format(new Date(item.date), 'yyyy'));
        return dates ? Math.min(...dates) : null;
    }
    get filteredResults() {
        return this.data?.results.filter((result) => result.visible === true);
    }
};
__decorate([
    Watch('biomarkerResult')
], AnalyzePage.prototype, "function", null);
__decorate([
    Watch('selectedVisibleResults')
], AnalyzePage.prototype, "setData", null);
AnalyzePage = __decorate([
    Component({
        components: {
            MainToggle,
            ChartMobileFilter,
            BackBtn,
            LaboratoryDesignation,
            ChartControl,
            ChooseSpecificDates,
            CheckboxInput,
            BaseBadge,
            LookDynamic,
            HistoryAnalyzes,
            InfoHeader,
            ChartComponent,
            ConvertModal,
            LoadingSpinner,
            MessageModal,
            ChangeUnitModal,
            ResultsChart
        },
    })
], AnalyzePage);
export default AnalyzePage;
