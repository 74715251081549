import { __decorate } from "tslib";
import { Component, Emit, Vue, Watch } from 'vue-property-decorator';
import { subDays } from 'date-fns';
import MainBtn from '@/components/UI/buttons/MainBtn.vue';
import BackBtn from '@/components/UI/buttons/BackBtn.vue';
import DatePicker from '@/components/UI/DatePicker.vue';
let ThirdType = class ThirdType extends Vue {
    constructor() {
        super(...arguments);
        this.localType = 2;
        this.active = 4;
        this.periodValue = {
            from: '',
            to: '',
        };
    }
    setPeriod(obj) {
        this.$store.commit('filter/setPropertyInStore', { name: 'selectedPeriod', value: 4 });
        const period = [subDays(new Date(obj.from), 0), subDays(new Date(obj.to), 0)];
        this.$store.commit('filter/setPropertyInStore', {
            name: 'selectedPeriodDateRange',
            value: period,
        });
    }
    changePeriod(obj) {
        return [subDays(new Date(obj.from), 0), subDays(new Date(obj.to), 0)];
    }
};
__decorate([
    Watch('periodValue', { deep: true })
], ThirdType.prototype, "setPeriod", null);
__decorate([
    Emit('change-date-range')
], ThirdType.prototype, "changePeriod", null);
ThirdType = __decorate([
    Component({
        components: { BackBtn, MainBtn, DatePicker },
    })
], ThirdType);
export default ThirdType;
