import { __decorate } from "tslib";
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import CheckboxInput from '@/components/UI/inputs/CheckboxInput.vue';
import MainBtn from '@/components/UI/buttons/MainBtn.vue';
import BackBtn from '@/components/UI/buttons/BackBtn.vue';
let FirstType = class FirstType extends Vue {
    constructor() {
        super(...arguments);
        this.localSelectedLabs = {};
    }
    changeType() {
        return 0;
    }
    function(val) {
        this.localSelectedLabs = { ...val };
    }
    created() {
        this.localSelectedLabs = { ...this.selectedLaboratories };
    }
    get selectedLaboratories() {
        return this.$store.state.filter.selectedLaboratories;
    }
    get laboratories() {
        return this.$store.state.filter.laboratoriesList;
    }
    onCheckChange(id) {
        const list = { ...this.localSelectedLabs };
        list[id] = !list[id];
        if (list.all) {
            for (const prop in list) {
                list[prop] = true;
            }
        }
        this.localSelectedLabs = { ...list };
    }
    setSelectedLabs() {
        this.$store.commit('filter/setPropertyInStore', { name: 'selectedLaboratories', value: this.localSelectedLabs });
        this.changeType();
    }
};
__decorate([
    Prop()
], FirstType.prototype, "laboratoryList", void 0);
__decorate([
    Emit('change-type')
], FirstType.prototype, "changeType", null);
__decorate([
    Watch('selectedLaboratories')
], FirstType.prototype, "function", null);
FirstType = __decorate([
    Component({
        components: { BackBtn, MainBtn, CheckboxInput },
    })
], FirstType);
export default FirstType;
