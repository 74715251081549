<template lang="pug">
MessageModal(v-bind="$attrs" v-on="$listeners" size="sm" hideOkButton)
  template(#body)
    .TakeAnalysisModal-Content
      .TakeAnalysisModal-Title Это исследование входит в несколько услуг и чек-апов ЛабСтори. Почитать подробнее вы можете на сайте labstori.ru, выбрав нужный пункт
      .TakeAnalysisModal-List
        TakeAnalysisItem(v-for="service in services" v-bind="service" :key="service.id")
</template>

<script>
import TakeAnalysisItem from './TakeAnalysisItem.vue';
import MessageModal from '@/components/modals_new/templates/MessageModal.vue';
export default {
  components: {MessageModal, TakeAnalysisItem},
  name: 'TakeAnalysisModal',
  props: {
    services: {type: Array}
  }
}
</script>

<style lang="scss">
.TakeAnalysisModal {
  height: 200px;
}

.TakeAnalysisModal-Content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.TakeAnalysisModal-Title {
  font-size: 14px;
  line-height: 20px;
  color: $grey-2;
  text-align: left;
}

.TakeAnalysisModal-List {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>