<template lang="pug">
a.TakeAnalysisItem(:href="link" target="_blank" @click="$metrika.reachGoal('userGoToCatalog')")
  .TakeAnalysisItem-Title {{title}}
  .TakeAnalysisItem-Id Код: {{code}}
  icon(name="next-icon2").TakeAnalysisItem-Arrow
</template>

<script>
export default {
  name: 'TakeAnalysisItem',
  props: {
    title: String,
    code: Number,
    link: String,
  }
}
</script>

<style lang="scss">

.TakeAnalysisItem {
  background: $light-background;
  border: 1px solid transparent;
  border-radius: 14px;
  padding: 12px;
  padding-right: 48px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-decoration: none;
  text-align: start;
  position: relative;
  transition: .3s;
  &:hover {
    border-color: $grey-6;
    background: white;
  }
}

.TakeAnalysisItem-Title {
  font-size: 12px;
  line-height: 15px;
  color: $grey-2;
}

.TakeAnalysisItem-Id {
  font-size: 12px;
  line-height: 15px;
  color: $grey-4;
}

.TakeAnalysisItem-Arrow {
  position: absolute;
  color: $accent-color;
  top: 50%;
  transform: translateY(-50%);
  width: 5px;
  height: 10px;
  right: 30px;
}
</style>